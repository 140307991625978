let nameClass     = 'hidden',
		menu          = document.querySelector('.js_menu'),
		menuContent   = document.querySelector(".nav_mobile_content"),
		navOpen       = document.querySelector(".nav_mobile"),
		btnClose      = document.querySelector(".close"),
		btnPopin      = document.querySelector(".js_video"),
		popin         = document.querySelector(".popin_video"),
		closeVideo    = document.querySelector(".close_video");

menu.addEventListener('click', function(event) {
	event.preventDefault();
  window.scrollTo(0, 300);
	menuContent.classList.toggle(nameClass);
	navOpen.classList.toggle('is_open');
});

btnClose.addEventListener('click', function() {
	menuContent.classList.add(nameClass);
	navOpen.classList.remove('is_open');
});

if(btnPopin) {
	btnPopin.addEventListener('click', function(e) {
		e.preventDefault();
		popin.classList.toggle(nameClass);
	})
}

if(closeVideo) {
	closeVideo.addEventListener('click', function() {
		popin.classList.add(nameClass);
	})
}

// MODE STICKY
let h = document.querySelector(".header"),
		stuck = false,
		stickPoint = getDistance();

function getDistance() {
  let topDist = h.offsetTop;
  return topDist;
}

window.onscroll = function(e) {
	let distance = getDistance() - window.scrollY,
			offset = window.scrollY;
	if ( (distance <= 0) && !stuck) {
		h.classList.add('fixed');

		stuck = true;
	} else if (stuck && (offset <= stickPoint)){
		h.classList.remove('fixed');
		stuck = false;
	}
}
